.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #fff !important;
  color: #5b5d61 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #fff !important;
  color: #5b5d61 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #029600 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #ff8a00 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #e3124b !important;
}
.Toastify__toast-body {
  border-radius: 0.6rem !important;
}

header .MuiTypography-root {
  margin-bottom: 0 !important;
}

.MuiModal-root {
  .MuiBox-root .css-1v3283w {
    width: auto;
  }
}
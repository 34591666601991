.mark-down {
    p {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 0rem;
        line-height: 1.5;
        text-align: left;
        font-family: 'Open Sans';
    }
}